import Script from 'next/script'

import { useFeatureFlag } from 'modules/featureFlags'
import { isRobot } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'

// Code from https://gamma-app.zendesk.com/admin/channels/messaging_and_social/channels_list/web_widget/30668582594196/edit

export const ZendeskScript = () => {
  const zeFlag = useFeatureFlag('zendesk')
  const enabled = zeFlag && !isRobot() && !shouldUsePublishedVersion()

  if (!useSSRMounted() || !enabled) return null

  return (
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=a0bef73f-af13-4ae9-ad67-25e2355724db"
      async
      defer
    />
  )
}
